<template>
  <b-card
    no-body
  >
    <show-history-quotation-sidebar
      :is-show-history-quotation-sidebar-active.sync="isShowHistoryQuotationSidebarActive"
      :histories="histories"
    />
    <quotation-sidebar-send-email
      :quotation="quotationData"
      :is-handler-sidebar-active.sync="isHandlerSidebarActive"
    />
    <show-quotation-client-sidebar
      :is-show-quotation-client-sidebar-active.sync="isShowQuotationClientSidebarActive"
      :client="clientData"
    />
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="">
            <h3>Total de cotizaciones</h3> <small> (Hay {{ totalQuotations }} cotizaciones)</small>
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="7"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar por código, contacto, o empresa"
            />
            <v-select
              v-model="filterQueryStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="quotationStatusOptions"
              class="w-100 mr-1"
              :reduce="val => val.value"
              placeholder="Filtro..."
            />
            <b-button
              variant="primary"
              :to="{ name: 'quotations-create', params: { currency: 'pen'}}"
            >

              <span class="text-nowrap">Crear PEN</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="info"
              :to="{ name: 'quotations-create', params: { currency: 'usd'}}"
            >
              <span class="text-nowrap">Crear USD</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refQuotationsListTable"
      :items="fetchQuotations"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      class="position-relative"
    >
      <!-- Column: Id -->

      <!-- Column: Business name -->
      <template #cell(business_name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.client)"
              variant="light-primary"
            />
          </template>
          <!--          <span class="font-weight-bold d-block text-wrap">-->
          <!--            {{ data.item.client }}-->
          <!--          </span>-->
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="showClientData(data.item)"
          >
            {{ data.item.client }}
          </b-link>
          <small class="text-muted">{{ data.item.business_name }}</small>
        </b-media>
      </template>

      <!-- Column: Quotation code -->
      <template #cell(quotation_code)="data">
        <b-media vertical-align="center">
          <b-link
            :to="{ name: 'quotations-show', params: { id : data.item.id} }"
            class="alert-link font-weight-bold d-block text-nowrap"
          >
            {{ data.item.quotation_code }}
          </b-link>
        </b-media>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(total)="data">
        <span class="text-nowrap">
          {{ data.item.currency_symbol }} {{ data.item.total }}
        </span>
      </template>

      <!-- Column: Quotation status -->
      <template #cell(quotation_status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.quotation_status)}`"
          class="text-capitalize"
        >
          {{ data.item.quotation_status }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`quotation-row-${data.item.id}-history-icon`"
            icon="BookIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="showHistory(data.item)"
          />
          <b-tooltip
            title="Historial de la cotización"
            class="cursor-pointer"
            :target="`quotation-row-${data.item.id}-history-icon`"
          />

          <template v-if="data.item.quotation_status === 'CADUCADO'">
            <feather-icon
              :id="`quotation-row-${data.item.id}-activate-icon`"
              icon="RefreshCwIcon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="$router.push({ name: 'quotations-edit', params: { id: data.item.id }})"
            />
            <b-tooltip
              title="Activar cotización"
              class="cursor-pointer"
              :target="`quotation-row-${data.item.id}-activate-icon`"
            />
          </template>

          <template v-if="data.item.quotation_status !== 'CADUCADO'">
            <feather-icon
              :id="`quotation-row-${data.item.id}-edit-icon`"
              icon="Edit2Icon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="$router.push({ name: 'quotations-edit', params: { id: data.item.id }})"
            />
            <b-tooltip
              title="Editar"
              :target="`quotation-row-${data.item.id}-edit-icon`"
            />
          </template>

          <template v-if="!['CADUCADO','PENDIENTE'].includes(data.item.quotation_status)">
            <feather-icon
              :id="`quotation-row-${data.item.id}-mail-icon`"
              icon="MailIcon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="sendEmail(data.item)"
            />
            <b-tooltip
              title="Enviar email"
              :target="`quotation-row-${data.item.id}-mail-icon`"
            />

          </template>

          <template v-if="!['CADUCADO','PENDIENTE'].includes(data.item.quotation_status)">
            <feather-icon

              :id="`sale-row-${data.item.id}-print-icon`"
              icon="FileTextIcon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="openPDF(data.item)"
            />
            <b-tooltip
              title="Descargar PDF"
              :target="`sale-row-${data.item.id}-print-icon`"
            />
          </template>

          <template v-if="data.item.expiration_date && data.item.quotation_status !== 'CADUCADO'">
            <feather-icon

              :id="`quotation-row-${data.item.id}-sale-icon`"
              icon="ShoppingCartIcon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="changeToSale(data.item)"
            />
            <b-tooltip
              title="Venta"
              :target="`quotation-row-${data.item.id}-sale-icon`"
            />
          </template>

          <feather-icon
            :id="`quotation-row-${data.item.id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="cursor-pointer"
          />
          <b-tooltip
            title="Eliminar"
            :target="`quotation-row-${data.item.id}-delete-icon`"
          />
          <b-popover
            :target="`quotation-row-${data.item.id}-delete-icon`"
            variant="danger"
            triggers="focus"
            placement="left"
          >
            <template #title>
              <span>Eliminar cotización</span>
            </template>

            <div class="">
              <p>
                ¿Estás seguro que desea eliminar la cotización <b>{{ data.item.quotation_code }}</b>?
              </p>
            </div>

            <b-button
              variant="outline-danger"
              class="mr-1"
              size="sm"
              @click="deleteQuotation(data.item)"
            >
              <span class="text-nowrap">Eliminar</span>
            </b-button>
          </b-popover>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          sm="6"-->
        <!--          class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
        <!--        >-->
        <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
        <!--        </b-col>-->
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalQuotations"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BTooltip,
  BPopover,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import quotationHandler from '@/views/dramox/quotation/quotationHandler'
import quotationStoreModule from '@/views/dramox/quotation/quotationStoreModule'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ShowHistoryQuotationSidebar from '@/views/dramox/quotation/ShowHistoryQuotationSidebar'
import QuotationSidebarSendEmail from '@/views/dramox/quotation/view/QuotationSidebarSendEmail'
import axios from '@axios'
import ShowQuotationClientSidebar from '@/views/dramox/quotation/ShowQuotationClientSidebar'

export default {
  name: 'HomeBusiness',
  components: {
    ShowQuotationClientSidebar,
    QuotationSidebarSendEmail,
    ShowHistoryQuotationSidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BLink,
    BBadge,
    BButton,
    vSelect,
    BPopover,
  },
  data() {
    return {
    }
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-quotations'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, quotationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()
    const isShowHistoryQuotationSidebarActive = ref(false)
    const isShowQuotationClientSidebarActive = ref(false)
    const histories = ref({})
    const quotationData = ref({})
    const clientData = ref({})
    const isHandlerSidebarActive = ref(false)

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const quotationStatusOptions = [
      { label: 'Sin filtro', value: null },
      { label: 'CREADO', value: 'CREADO' },
      { label: 'PENDIENTE', value: 'PENDIENTE' },
      { label: 'CREADO Y ENVIADO', value: 'CREADO Y ENVIADO' },
      { label: 'CADUCADO', value: 'CADUCADO' },
    ]

    const {
      tableColumns,
      currentPage,
      totalQuotations,
      perPage,
      searchQuery,
      filterQueryStatus,
      refQuotationsListTable,
      refetchData,
      fetchQuotations,
      resolveStatusVariant,
    } = quotationHandler()

    const deleteQuotation = item => {
      store.dispatch('app-quotations/deleteQuotation', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Cotización eliminada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }
    const changeToSale = item => {
      store.dispatch('app-quotations/changeQuotationToSale', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Venta agregada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(error => {
          showNotification({
            title: 'Oops!, ocurrió un error', text: error.response.data.error.message, icon: 'AlertTriangleIcon', variant: 'danger',
          })
        })
    }
    const showHistory = item => {
      isShowHistoryQuotationSidebarActive.value = false
      store.dispatch('app-quotations/showHistoryQuotation', {
        queryParams: {
          quotationId: item.id,
          contactId: item.contact_id,
        },
      })
        .then(response => {
          histories.value = response.data.payload.results
          // showNotification({ title: 'Venta agregada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          isShowHistoryQuotationSidebarActive.value = true
        })
        .catch(error => {
          showNotification({
            title: 'Oops!, ocurrió un error', text: error.response.data.error.message, icon: 'AlertTriangleIcon', variant: 'danger',
          })
        })
    }

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/pdf/quotation/${val.id}`
      window.open(url, '_blanck')
    }

    const showClientData = val => {
      isShowQuotationClientSidebarActive.value = true
      clientData.value = val
    }

    const sendEmail = item => {
      const { contact_id: contacto_id, ...rest } = item
      quotationData.value = {
        ...rest,
        contacto_id,
      }
      isHandlerSidebarActive.value = true
    }

    return {
      fetchQuotations,
      tableColumns,
      currentPage,
      totalQuotations,
      searchQuery,
      filterQueryStatus,
      perPage,
      refQuotationsListTable,
      refetchData,
      avatarText,
      resolveStatusVariant,
      quotationStatusOptions,

      deleteQuotation,
      changeToSale,

      isShowHistoryQuotationSidebarActive,
      isShowQuotationClientSidebarActive,
      histories,
      showHistory,

      quotationData,
      clientData,
      isHandlerSidebarActive,
      sendEmail,

      showClientData,

      openPDF,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function quotationHandler() {
  // Use toast
  const toast = useToast()

  const refQuotationsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'quotation_code', label: 'Código', sortable: false },
    // { key: 'client', label: 'Contacto', sortable: false },
    { key: 'business_name', label: 'Cliente', sortable: false },
    { key: 'total', label: 'Total', sortable: false },
    { key: 'quotation_status', label: 'Estado', sortable: false },
    { key: 'expiration_date', label: 'F. Vencimiento', sortable: false },
    { key: 'actions',label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalQuotations = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const filterQueryStatus = ref('')

  const refetchData = () => {
    refQuotationsListTable.value.refresh()
  }

  watch([currentPage, searchQuery,filterQueryStatus], () => {
    refetchData()
  })

  const fetchQuotations = (ctx, callback) => {
    store
      .dispatch('app-quotations/fetchQuotations', {
        searchText: searchQuery.value,
        status: filterQueryStatus.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results

        callback(data)
        totalQuotations.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching quotations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveStatusVariant = status => {
    if (status === 'VENTA') return 'success'
    if (status === 'ANULADO') return 'danger'
    if (status === 'CADUCADO') return 'danger'
    if (status === 'PENDIENTE') return 'warning'
    if (status === 'CREADO Y ENVIADO') return 'info'
    return 'primary'
  }

  return {
    fetchQuotations,
    tableColumns,
    perPage,
    currentPage,
    totalQuotations,
    searchQuery,
    filterQueryStatus,
    refQuotationsListTable,
    refetchData,
    resolveStatusVariant,
  }
}

<template>
  <b-sidebar
    id="add-new-contact-sidebar"
    :visible="isShowQuotationClientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-show-quotation-client-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Cliente
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <div class="p-2">
        <b-row>
          <b-col
              cols="12"
              md="6"
          >
            <!-- Contact Name -->
            <b-form-group
                label="Contacto"
                label-for="client"
            >
              <b-form-input
                  id="client"
                  :value="client.client"
                  plaintext
              />
            </b-form-group>

          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <!-- Contact Position -->
            <b-form-group
                label="Cargo"
                label-for="position"
            >
              <b-form-input
                  id="position"
                  :value="client.position"
                  plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <!-- Contact Phone -->
            <b-form-group
                label="Teléfono"
                label-for="phone"
            >
              <b-form-input
                  id="phone"
                  :value="client.phone"
                  plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <!-- Contact mobilePhone -->
            <b-form-group
                label="Celular"
                label-for="mobilephone"
            >
              <b-form-input
                  id="mobilephone"
                  :value="client.mobilePhone"
                  plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="12"
          >
            <!-- Contact Email -->
            <b-form-group
                label="Email"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  :value="client.email"
                  plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="12"
          >
            <!-- Business name -->
            <b-form-group
                label="Empresa"
                label-for="business_name"
            >
              <b-form-input
                  id="business_name"
                  :value="client.business_name"
                  plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="12"
          >
            <!-- RUC -->
            <b-form-group
                label="RUC"
                label-for="ruc"
            >
              <b-form-input
                  id="ruc"
                  class="text-wrap"
                  :value="client.ruc"
                  plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="12"
          >
            <!-- RUC -->
            <b-form-group
                label="Dirección fiscal"
                label-for="address_fiscal"
            >
              <b-form-textarea
                  id="address_fiscal"
                  class="text-wrap"
                  :value="client.address_fiscal"
                  plaintext
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BRow, BCol,BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShowQuotationClientSidebarActive',
    event: 'update:is-show-quotation-client-sidebar-active',
  },
  props: {
    isShowQuotationClientSidebarActive: {
      type: Boolean,
      required: true,
    },
    client: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
</script>

<template>
  <b-sidebar
    id="add-new-contact-sidebar"
    :visible="isShowHistoryQuotationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-show-quotation-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Historial
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <div class="p-2">
        <!--        <b-row>-->
        <!--          <b-col-->
        <!--              cols="12"-->
        <!--              md="6"-->
        <!--          >-->
        <!--            &lt;!&ndash; Contact Name &ndash;&gt;-->
        <!--            <b-form-group-->
        <!--                label="Nombre"-->
        <!--                label-for="name"-->
        <!--            >-->
        <!--              <b-form-input-->
        <!--                  id="name"-->
        <!--                  :value="histories.name"-->
        <!--                  plaintext-->
        <!--              />-->
        <!--            </b-form-group>-->

        <!--          </b-col>-->
        <!--          <b-col-->
        <!--              cols="12"-->
        <!--              md="6"-->
        <!--          >-->
        <!--            &lt;!&ndash; Contact phone &ndash;&gt;-->
        <!--            <b-form-group-->
        <!--                label="Teléfono"-->
        <!--                label-for="phone"-->
        <!--            >-->
        <!--              <b-form-input-->
        <!--                  id="phone"-->
        <!--                  :value="histories.phone"-->
        <!--                  plaintext-->
        <!--              />-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--          <b-col-->
        <!--              cols="12"-->
        <!--              md="6"-->
        <!--          >-->
        <!--            &lt;!&ndash; Contact MobilePhone &ndash;&gt;-->
        <!--            <b-form-group-->
        <!--                label="Teléfono"-->
        <!--                label-for="phone"-->
        <!--            >-->
        <!--              <b-form-input-->
        <!--                  id="phone"-->
        <!--                  :value="histories.mobilePhone"-->
        <!--                  plaintext-->
        <!--              />-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--          <b-col-->
        <!--              cols="12"-->
        <!--              md="12"-->
        <!--          >-->
        <!--            &lt;!&ndash; Contact Email &ndash;&gt;-->
        <!--            <b-form-group-->
        <!--                label="Email"-->
        <!--                label-for="email"-->
        <!--            >-->
        <!--              <b-form-input-->
        <!--                  id="email"-->
        <!--                  class="text-wrap"-->
        <!--                  :value="histories.email"-->
        <!--                  plaintext-->
        <!--              />-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--        </b-row>-->

        <!--        <h3 class="my-1">Historial</h3>-->
        <app-timeline>
          <app-timeline-item
            v-for="(history,index) in histories.histories"
            :key="index"
          >
            <div class="">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>{{ history.created_at }}</h3>
              </div>
              <div>
                <p v-html="history.action" />
                <!--                <p>Fecha: <b>{{ history.created_at }}</b></p>-->
                <p>
                </p>
              </div>
            </div>
          </app-timeline-item>
        </app-timeline>
        <p>Fecha de creación: {{ histories.quotationCreatedAt }}</p>
      </div>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
} from 'bootstrap-vue'
import { ref, toRefs } from '@vue/composition-api'
import {
  required, alphaNum, email, numeric, digits,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem'
import AppTimeline from '@core/components/app-timeline/AppTimeline'

export default {
  components: {
    BSidebar,

    AppTimelineItem,
    AppTimeline,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShowHistoryQuotationSidebarActive',
    event: 'update:is-show-quotation-sidebar-active',
  },
  props: {
    isShowHistoryQuotationSidebarActive: {
      type: Boolean,
      required: true,
    },
    histories: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      numeric,
      digits,
    }
  },
  setup(props, { emit }) {
    const blankBusinessData = {
      ruc: '',
      business_name: '',
      website: '',
      phone: '',
      address_fiscal: '',
      address_fiscal_1: '',
      address_fiscal_2: '',
      department: '',
      province: '',
      district: '',
      service_interest: null,
      status_business: '',
    }

    const businessObject = ref(JSON.parse(JSON.stringify(blankBusinessData)))

    const resetBusinessData = () => {
      businessObject.value = JSON.parse(JSON.stringify(blankBusinessData))
    }

    // const onSubmit = () => {
    //   store.dispatch('app-user/addUser', businessData.value)
    //     .then(() => {
    //       emit('refetch-data')
    //       emit('update:is-add-new-user-sidebar-active', false)
    //     })
    // }

    // const {
    //   // businessObjectLocal,
    //   // resetBusinessObjectLocal,
    //   // UI
    //   // onSubmit,
    // } = businessHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBusinessData)

    const onSubmit = () => {
      emit('add-business', businessObject.value)
      emit('update:is-add-new-business-sidebar-active', false)
      // emit('refetch-data')
    }

    return {
      businessObject,
      // businessData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
